.App {
  text-align: center;
  background-color: #293040;
  color: white;
}


.App-logo {
  width: 450px;
  pointer-events: none;
  border-radius: 1em;
  max-width: 90%;
}

.App-page {
  overflow: hidden;
  font-size: calc(10px + 2vmin);
}

.App-header {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
  border-bottom: 1px #C9AD8B solid;
}

.App-contact {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1, h2, h3, h4, h5, h6, a {
  color: #C9AD8B;
}

.App-products {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.App-product {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 300px;
  gap: 16px;
  padding-bottom: 1em;
  border-bottom: 1px solid #C9AD8B;
}

.App-ref-clock-image {
  max-width: 100%;
}

